@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Define base color variables */
    --light-color: #ffffff;
    --dark-color: #000000;

    /* Generate shades of the primary color */

    --color-primary: #fa873e;
    --color-primary50: color-mix(
      in srgb,
      rgba(var(--color-primary)) 10%,
      var(--light-color)
    );
    --color-primary100: color-mix(
      in srgb,
      rgba(var(--color-primary)) 20%,
      var(--light-color)
    );
    --color-primary200: color-mix(
      in srgb,
      rgba(var(--color-primary)) 30%,
      var(--light-color)
    );
    --color-primary300: color-mix(
      in srgb,
      rgba(var(--color-primary)) 40%,
      var(--light-color)
    );
    --color-primary400: color-mix(
      in srgb,
      rgba(var(--color-primary)) 50%,
      var(--light-color)
    );
    --color-primary500: rgb(var(--color-primary));
    --color-primary600: color-mix(
      in srgb,
      rgba(var(--color-primary)) 70%,
      var(--dark-color)
    );
    --color-primary700: color-mix(
      in srgb,
      rgba(var(--color-primary)) 80%,
      var(--dark-color)
    );
    --color-primary800: color-mix(
      in srgb,
      rgba(var(--color-primary)) 90%,
      var(--dark-color)
    );
    --color-primary900: color-mix(
      in srgb,
      rgba(var(--color-primary)) 100%,
      var(--dark-color)
    );
  }

  .btn-active {
    @apply bg-primary500 px-[10px] h-[30px] rounded-[5px] text-sm text-white;
  }
  .btn-inactive {
    @apply bg-grey10 px-[10px] h-[30px]  text-sm  text-neutral500;
  }
}

* {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
}

select,
input {
  background: inherit;
}
